/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

$my-palette: mat.define-palette(mat.$red-palette);
$row-highlight-color: mat.get-color-from-palette($my-palette, "lighter");

/* For use in src/lib/core/theming/_palette.scss */
$md-coolgreen: (
  50: #e0f1e9,
  100: #b3dcc8,
  200: #80c5a4,
  300: #4dae7f,
  400: #269c63,
  500: #008b48,
  600: #008341,
  700: #007838,
  800: #006e30,
  900: #005b21,
  A100: #8cffab,
  A200: #59ff85,
  A400: #26ff60,
  A700: #0dff4d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-coolyellow: (
  50: #fffbe0,
  100: #fff5b3,
  200: #ffee80,
  300: #ffe74d,
  400: #ffe226,
  500: #ffdd00,
  600: #ffd900,
  700: #ffd400,
  800: #ffcf00,
  900: #ffc700,
  A100: #ffffff,
  A200: #fffcf2,
  A400: #ffefbf,
  A700: #ffe9a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-typography: mat.define-typography-config(
  $font-family: "Poppins",
);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($md-coolgreen),
      accent: mat.define-palette($md-coolyellow),
    ),
    typography: $my-typography,
    density: 0,
  )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  @include mat.typography-level($my-typography, "body-1");
}

html {
  visibility: hidden;
}
html.wf-active {
  visibility: visible;
}

.bg-cool-light-green {
  @apply bg-[#E8F0E6];
}

.bg-cool-green {
  @apply bg-[#008B47];
}

.text-cool-green {
  @apply text-[#008B47];
}

.bg-cool-yellow {
  @apply bg-[#FFDD00];
}

.bg-cool-yellow-force {
  @apply bg-[#FFDD00] #{!important};
}

.text-cool-yellow {
  @apply text-[#FFDD00];
}

td.mat-column-actions {
  width: 120px;
  padding-right: 8px;
  white-space: nowrap;
}

.list-container {
  padding: 0 16px;
  height: calc(100% - 96px); // 80px is the height of the toolbar + 16px for bottom margin
  max-height: calc(100% - 96px);
}

.tab-panel {
  display: block;
  overflow: auto;
  height: calc(100% - 52px); // 52px is the height of the tab header + 16px for bottom margin
  max-height: calc(100% - 52px);
}

.table-panel {
  display: block;
  overflow: auto;
  @apply shadow-md mt-4;
}

.fill { 
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}

.margin-bottom {
  margin-bottom: 16px;
}

.example-spacer {
  flex: 1 1 auto;
}

.message-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: #d60e0e;
    padding: 5px;
  }
}

@keyframes flash {
  50% {
    background-color: $row-highlight-color;
  }
  100% {
    background-color: inherit;
  }
}

.flash {
  animation: flash 1s linear;
}

.horizontal-form {
  mat-form-field {
    margin-right: 12px;
  }
}

.fields-container {
  padding: 0 16px 16px 16px;
}

mat-form-field {
  width: 100%;
}

.margin-top {
  margin-top: 16px;
}

mat-dialog-content.no-padding {
  padding: 0 !important;
}

.padding {
  padding: 16px;
}

.block {
  display: block;
}

mat-form-field.file {
  min-width: 250px;
  width: 50%;

  + button {
    margin-left: 8px;
  }
}

.dense {
  @include mat.all-component-densities(-1);
}

.text-align-center-all-browsers {
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
}

// Override the default height of the subscript wrapper when sizing dynamically.
.mat-mdc-form-field-subscript-wrapper {
  min-height: 22px;
}

.mat-mdc-form-field-subscript-wrapper-no-min-height {
  .mat-mdc-form-field-subscript-wrapper {
    min-height: unset !important;
  }
}

.no-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

section {
  @apply pb-20;
}

.error-notification.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    @apply bg-warn-500 text-white px-0 text-center;
  }
}

.info-notification.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    @apply bg-blue-500 text-white px-0 text-center;
  }
}

.mat-mdc-radio-full-width.mat-mdc-radio-button {
  .mdc-form-field {
    width: 100%;
  }
}


.mat-mdc-radio-full-width.mat-mdc-radio-button {
  .mdc-form-field>label {
    width: 100%;
  }
}

.coverage-map-dialog {
  @apply w-[100vw] #{!important};
  @apply max-w-sm md:max-w-prose #{!important};
  @apply px-4;
}

.address-dialog {
  @apply w-[100vw] #{!important};
  @apply max-w-sm md:max-w-prose #{!important};
  @apply px-4;

  .mat-mdc-dialog-content {
    @apply p-0 #{!important};
    @apply items-center justify-center;

    .form-background {
      @apply bg-white;
    }
  }
}

.shopping-cart-related-items {
  
  .mat-expansion-panel{
    @apply shadow-none #{!important};
    @apply rounded-2xl #{!important};
  }

  .mat-expansion-indicator::after {
    @apply border-primary;
  }

  .mat-expansion-panel-body {
    @apply p-2 pt-0 #{!important};
  }
}

.custom-dialog-container {
  border-radius: 16px !important;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .custom-dialog-container {
    max-width: 500px !important;
  }
}